import stefanAnimation from './stefan.json';

let droppedFile = null;
let participants = [];
let responses = {};
let devMode = false;
let questions = null;

function handleDrop(e) {
    console.log('Drop event triggered');
    e.preventDefault();
    e.stopPropagation();
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        console.log('File dropped:', file.name, file.type);
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];
        
        if (validTypes.includes(file.type)) {
            droppedFile = file;
            const reader = new FileReader();
            
            reader.onload = function(event) {
                document.getElementById('dropZone').innerHTML = '<img src="' + event.target.result + '">';
            }
            
            reader.readAsDataURL(droppedFile);
        } else {
            alert('Please upload a valid image file (JPEG, JPG, PNG, GIF, or WebP)');
        }
    }
}

function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
}

function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById('dropZone').style.backgroundColor = 'rgba(74, 144, 226, 0.2)';
}

function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById('dropZone').style.backgroundColor = 'rgba(74, 144, 226, 0.1)';
}

document.addEventListener('DOMContentLoaded', function() {
    const dropZone = document.getElementById('dropZone');
    dropZone.addEventListener('drop', handleDrop);
    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('dragenter', handleDragEnter);
    dropZone.addEventListener('dragleave', handleDragLeave);

});

// Prevent default drag behaviors on the entire document
['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    document.body.addEventListener(eventName, preventDefaults, false);
});

function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
}




document.getElementById('devModeToggle').addEventListener('change', function(event) {
    devMode = this.checked;
    handleDevModeToggle(event);
    fetch(`/api/toggle_dev_mode?enable=${devMode}`)
        .then(response => response.json())
        .then(data => console.log('Dev mode:', data.dev_mode))
        .catch(error => console.error('Error toggling dev mode:', error));
});


async function startReview() {
    if (!droppedFile) {
        alert('Please drop an image first');
        return;
    }

    const adName = document.getElementById('adName').value;
    if (!adName) {
        alert('Please enter an ad name');
        return;
    }

    const selectedAudience = document.getElementById('audienceSelector').value;
    const selectedQuestionSet = document.getElementById('questionSetSelector').value;

    document.getElementById('result').innerHTML = '';
    document.getElementById('progressBarContainer').style.display = 'block';
    const progressBar = document.getElementById('progressBar');
    let progress = 0;
    let resultReceived = false;

    const progressInterval = setInterval(() => {
        if (progress < 90) {
            progress += 0.6;
            progressBar.style.width = `${progress}%`;
        } else if (!resultReceived) {
            progressBar.style.width = '90%';
        }
    }, 100);

    try {
        const formData = new FormData();
        formData.append('file', droppedFile);
        formData.append('ad_name', adName);
        formData.append('audience', selectedAudience);
        formData.append('question_set', selectedQuestionSet);

        const response = await fetch('/api/analyze', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.questions) {
            questions = data.questions; // Store questions globally
        } else {
            console.error('No questions data received from server');
            questions = { questions: [] }; // Provide a default value
        }

        resultReceived = true;
        clearInterval(progressInterval);
        progressBar.style.width = '100%';
        
        setTimeout(() => {
            document.getElementById('progressBarContainer').style.display = 'none';
            displayResults(data);
        
            document.getElementById('participantsContainer').style.display = 'block';
            
            // Fetch participants and responses
            fetchParticipantsAndResponses();
        }, 500);
    } catch (error) {
        console.error('Detailed error:', error);
        let errorMessage = 'An error occurred while processing the request.';
        if (error.message.includes('HTTP error!')) {
            errorMessage = error.message;
        } else if (error instanceof TypeError) {
            errorMessage = 'Network error or server did not respond. Please check your connection and try again.';
        } else if (error instanceof SyntaxError) {
            errorMessage = 'There was a problem with the server response. Please check the server logs for more details.';
        }
        document.getElementById('progressBarContainer').style.display = 'none';
        document.getElementById('result').innerHTML = `<div class="error">${errorMessage}</div>`;
    }
}

async function fetchParticipantsAndResponses() {
    try {
        console.log('Fetching participants and responses...');
        const selectedAudience = document.getElementById('audienceSelector').value;
        const response = await fetch(`/api/participants_and_responses?audience=${selectedAudience}`);
        console.log('Response status:', response.status);
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }
        const data = await response.json();
        console.log('Received data:', data);
        participants = data.participants || [];
        responses = data.responses || {};
        displayParticipants();
    } catch (error) {
        console.error('Error fetching participants and responses:', error);
        document.getElementById('participants').innerHTML = '<p>Failed to load participants. Please check the server logs.</p>';
    }
}

function displayResults(data) {
    const resultDiv = document.getElementById('result');
    resultDiv.innerHTML = ''; // Clear previous results

    // Helper function to create and append elements
    function appendElement(parent, tag, content, className) {
        const element = document.createElement(tag);
        if (content) element.innerHTML = formatContent(content);
        if (className) element.className = className;
        parent.appendChild(element);
        return element;
    }

    // Helper function to format content (handle bold and italics)
    function formatContent(content) {
        return content
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            .replace(/\*(.*?)\*/g, '<em>$1</em>')
            .replace(/\n/g, '<br>');
    }

    // Create and append results elements
    appendElement(resultDiv, 'h2', `Results for ${data.ad_name}`);
    appendElement(resultDiv, 'p', `<strong>Average Score:</strong> ${data.average_score}/10`);
    appendElement(resultDiv, 'p', `<strong>Number of Participants:</strong> ${data.number_of_participants}`);
    
    const sentimentP = appendElement(resultDiv, 'p');
    sentimentP.innerHTML = `<strong>Sentiment Analysis:</strong> 
        Positive: ${data.sentiment_positive.toFixed(1)}%, 
        Neutral: ${data.sentiment_neutral.toFixed(1)}%, 
        Negative: ${data.sentiment_negative.toFixed(1)}%`;

    appendElement(resultDiv, 'p', data.sentiment_explanation);

    appendElement(resultDiv, 'p', `<strong>Summary:</strong> ${data.response_summary}`);

    appendElement(resultDiv, 'p', '<strong>Improvement Suggestions:</strong>');

    const suggestionsDiv = appendElement(resultDiv, 'div');
    const suggestionsList = appendElement(suggestionsDiv, 'ul');

    // Process improvement suggestions
    const suggestions = data.improvement_suggestions.trim().split('\n');
    suggestions.forEach(suggestion => {
        if (suggestion.trim().startsWith('*')) {
            appendElement(suggestionsList, 'li', suggestion.trim().substring(1).trim());
        }
    });
}

function displayParticipants() {
    const participantsContainer = document.getElementById('participants');
    participantsContainer.innerHTML = '';

    if (!participants || participants.length === 0) {
        participantsContainer.innerHTML = '<p>No participants available.</p>';
        return;
    }

    participants.forEach(participant => {
        if (!participant || !participant.name) {
            console.warn('Invalid participant data:', participant);
            return;
        }

        const participantElement = document.createElement('div');
        participantElement.className = 'participant';
        participantElement.innerHTML = `
            <div class="participant-circle">${participant.name[0] || '?'}</div>
            <div class="participant-name">${participant.name}</div>
        `;

        participantElement.addEventListener('click', () => {
            displayIndividualResult(participant.id);
        });

        participantsContainer.appendChild(participantElement);
    });
}

async function displayIndividualResult(participantId) {
    const panel = document.getElementById('individualResultPanel');
    const participantResponses = responses[participantId];

    if (participantResponses) {
        // Fetch the persona description
        const description = await fetchPersonaDescription(participantId);

        panel.innerHTML = `
            <h2>Results for Participant ${participantId}</h2>
            ${questions && questions.questions ? questions.questions.map(question => `
                <p><strong>${question.text}:</strong> ${participantResponses[question.id] || 'No response'}</p>
            `).join('') : '<p>No questions available</p>'}
            <p><strong>About this participant:</strong></p>
            <p>${description}</p>
        `;
        panel.style.display = 'block';
    } else {
        panel.innerHTML = '<p>No results available for this participant.</p>';
        panel.style.display = 'block';
    }
}

async function fetchPersonaDescription(participantId) {
    try {
        const response = await fetch(`/api/persona_description/${participantId}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.description;
    } catch (error) {
        console.error('Error fetching persona description:', error);
        return 'Description not available';
    }
}

import devImageUrl from './images/dev.jpeg';

function handleDevModeToggle(event) {
    const isChecked = event.target.checked;
    const adNameInput = document.getElementById('adName');
    const dropZone = document.getElementById('dropZone');

    if (isChecked) {
        adNameInput.value = 'Test';
        dropZone.innerHTML = `<img src="${devImageUrl}" alt="Dev mode image">`;
        fetch(devImageUrl)
            .then(res => res.blob())
            .then(blob => {
                droppedFile = new File([blob], "dev.jpeg", { type: "image/jpeg" });
            });
    } else {
        adNameInput.value = '';
        dropZone.innerHTML = '<p>Drag and drop image here</p>';
        droppedFile = null;
    }
}



document.addEventListener('DOMContentLoaded', function() {
    // Add event listeners
    const dropZone = document.getElementById('dropZone');
    dropZone.addEventListener('drop', handleDrop);
    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('dragenter', handleDragEnter);
    dropZone.addEventListener('dragleave', handleDragLeave);

    lottie.loadAnimation({
        container: document.getElementById('lottie'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: stefanAnimation  // Use the imported JSON directly
    });

    const devModeToggle = document.getElementById('devModeToggle');
    if (devModeToggle) {
        devModeToggle.addEventListener('change', (event) => {
            devMode = event.target.checked;
            handleDevModeToggle(event);
            fetch(`/api/toggle_dev_mode?enable=${devMode}`)
                .then(response => response.json())
                .then(data => console.log('Dev mode:', data.dev_mode))
                .catch(error => console.error('Error toggling dev mode:', error));
        });
    }
    document.addEventListener('click', (e) => {
        const panel = document.getElementById('individualResultPanel');
        if (panel.style.display === 'block' && !panel.contains(e.target) && !e.target.closest('.participant')) {
            panel.style.display = 'none';
        }
    });
    // Add event listener for startReviewButton
    const startReviewButton = document.getElementById('startReviewButton');
    startReviewButton.addEventListener('click', startReview);
});
